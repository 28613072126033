import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
};

export type CreateSubscriptionInput = {
  emailNum: Scalars['Float'];
  locationNum: Scalars['Float'];
  smsNum: Scalars['Float'];
  stripeId: Scalars['String'];
  userNum: Scalars['Float'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  businessBlurb?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  emailBlurb?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locationLabel?: Maybe<Scalars['String']>;
  mainContact?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  reviews: Array<Review>;
  textBlurb?: Maybe<Scalars['String']>;
  users: Array<User>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createFacebookReviews: Array<Review>;
  createLocation: Location;
  createNocowboysPage: Array<Review>;
  createSubscription: Subscription;
  createTeam: Team;
  login: AuthPayload;
  signup: AuthPayload;
  updateProfile: User;
  updateSubscription: Subscription;
  updateTeamMember: User;
  updateUser: User;
};


export type MutationCreateNocowboysPageArgs = {
  locationId: Scalars['String'];
  url: Scalars['String'];
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['String'];
  input: UpdateSubscriptionInput;
};


export type MutationUpdateTeamMemberArgs = {
  id: Scalars['String'];
  input: UpdateInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateInput;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['String'];
  paymentDate?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  team: Team;
  test: Scalars['String'];
  user: User;
};

export type Review = {
  __typename?: 'Review';
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  nativeRating?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  recommends?: Maybe<Scalars['Boolean']>;
  reviewerName: Scalars['String'];
  source: Scalars['String'];
};

export type SignupInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  customerStripeId: Scalars['String'];
  emailNum?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  locationNum?: Maybe<Scalars['Float']>;
  payments: Array<Payment>;
  smsNum?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  subscriptionStripeId: Scalars['String'];
  team: Team;
  userNum?: Maybe<Scalars['Float']>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['String'];
  locations: Array<Location>;
  signupStep: Scalars['String'];
  stripeId: Scalars['String'];
  subscription: Subscription;
  users: Array<User>;
};

export type UpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UpdateSubscriptionInput = {
  emailNum?: InputMaybe<Scalars['Float']>;
  locationNum?: InputMaybe<Scalars['Float']>;
  smsNum?: InputMaybe<Scalars['Float']>;
  stripeId?: InputMaybe<Scalars['String']>;
  userNum?: InputMaybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  reviews: Array<Review>;
  role: Scalars['String'];
};

export type UserFieldsFragment = { __typename?: 'User', id: string, name: string, phoneNumber?: string | null, profilePicUrl?: string | null, role: string, email: string };

export type TeamFieldsFragment = { __typename?: 'Team', id: string, signupStep: string };

export type LocationFieldsFragment = { __typename?: 'Location', id: string, address?: string | null, businessBlurb?: string | null, businessName?: string | null, emailBlurb?: string | null, locationLabel?: string | null, mainContact?: string | null, photoUrl?: string | null, textBlurb?: string | null };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string, user: { __typename?: 'User', id: string, name: string, phoneNumber?: string | null, profilePicUrl?: string | null, role: string, email: string } } };

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'AuthPayload', token: string, user: { __typename?: 'User', id: string, name: string, phoneNumber?: string | null, profilePicUrl?: string | null, role: string, email: string } } };

export type TeamQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamQuery = { __typename?: 'Query', team: { __typename?: 'Team', id: string, signupStep: string, locations: Array<{ __typename?: 'Location', id: string, address?: string | null, businessBlurb?: string | null, businessName?: string | null, emailBlurb?: string | null, locationLabel?: string | null, mainContact?: string | null, photoUrl?: string | null, textBlurb?: string | null }> } };

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  name
  phoneNumber
  profilePicUrl
  role
  email
}
    `;
export const TeamFieldsFragmentDoc = gql`
    fragment TeamFields on Team {
  id
  signupStep
}
    `;
export const LocationFieldsFragmentDoc = gql`
    fragment LocationFields on Location {
  id
  address
  businessBlurb
  businessName
  emailBlurb
  locationLabel
  mainContact
  photoUrl
  textBlurb
}
    `;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    token
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignupInput!) {
  signup(input: $input) {
    token
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const TeamDocument = gql`
    query Team {
  team {
    ...TeamFields
    locations {
      ...LocationFields
    }
  }
}
    ${TeamFieldsFragmentDoc}
${LocationFieldsFragmentDoc}`;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamQuery(baseOptions?: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;