import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useTeamQuery } from "./generated/graphql";
import { Login } from "./pages/Login";

function App() {
  const locationId = localStorage.getItem("location");

  const { data: teamData, loading } = useTeamQuery({
    onCompleted: (data) => {
      if (!locationId) {
        const [location] = data.team.locations;

        localStorage.setItem("location", location.id);
      }
    },
  });

  if (loading) return <div>Loading...</div>;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
