import { useForm } from "react-hook-form";
import { useLoginMutation, useSignupMutation } from "../generated/graphql";

interface LoginFormFields {
  name: string;
  email: string;
  password: string;
}

export const Login = () => {
  const [login] = useLoginMutation({
    onCompleted: (data) => {
      localStorage.setItem("token", data.login.token);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const [signup] = useSignupMutation({
    onCompleted: (data) => {
      localStorage.setItem("token", data.signup.token);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormFields>();

  const onSignup = ({ email, password, name }: LoginFormFields) =>
    signup({
      variables: {
        input: {
          email,
          password,
          name,
        },
      },
    });

  const onLogin = ({ email, password }: LoginFormFields) =>
    login({
      variables: {
        input: {
          email,
          password,
        },
      },
    });

  return (
    <div>
      <div>Create Account</div>
      <div>
        <div>Facebook</div>
        <div>Google</div>
      </div>
      <div>or use email for registration</div>
      <input {...register("name", { required: true })} placeholder="name" />
      <input {...register("email", { required: true })} placeholder="email" />
      <input
        {...register("password", { required: true })}
        placeholder="password"
      />
      <div>
        <div onClick={handleSubmit(onSignup)}>Sign Up</div>
        <div onClick={handleSubmit(onLogin)}>login</div>
      </div>
    </div>
  );
};
